import React from "react";
import { Link } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import Layout from "../components/layout";
import BannerImage1 from "../assets/images/banners/b1.jpg";
import BannerImage2 from "../assets/images/banners/b2.jpg";
import BannerImage3 from "../assets/images/banners/b3.jpg";
import BannerImage4 from "../assets/images/banners/b4.jpg";
import WelcomeBanner from "../assets/images/welcome.png";
import WaterIcon from "../assets/images/water.png";
import StreetLightIcon from "../assets/images/street-light.png";
import SafetyIcon from "../assets/images/safety.png";
import RoadIcon from "../assets/images/road.png";
import DrainageIcon from "../assets/images/drainage.png";
import SupportIcon from "../assets/images/support.png";
import Collage1 from "../assets/images/association-actions/collage-1.jpg";
import Collage2 from "../assets/images/association-actions/collage-2.jpg";
import Collage3 from "../assets/images/association-actions/collage-3.jpg";
import Collage4 from "../assets/images/association-actions/collage-4.jpg";
import Collage5 from "../assets/images/association-actions/collage-5.jpg";
import Collage6 from "../assets/images/association-actions/collage-6.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/pages/index.scss";

const activities = [
  {
    title: "Metro Water",
    description:
      "Without ground water supply, metro water is must for living in our area",
    icon: WaterIcon,
  },
  {
    title: "Street Lights",
    description: "People are using their own electricity for street lights",
    icon: StreetLightIcon,
  },
  {
    title: "Roads",
    description: "There is no government laid road in our area at all",
    icon: RoadIcon,
  },
  {
    title: "Drainage System",
    description: "Lack of drainage system make our area nastier in rainy days",
    icon: DrainageIcon,
  },
  {
    title: "Flood",
    description: "Every year we face above thigh level water log in our area",
    icon: SupportIcon,
  },
  {
    title: "Safety",
    description: "Women feeling unsafe to walk on the roads in the evening",
    icon: SafetyIcon,
  },
];

export default function Home() {
  return (
    <Layout>
      <div className="hero-banner">
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          autoPlay
          className="home-banner"
        >
          <div>
            <img src={BannerImage1} alt="NLRRWA Office Bearers" />
            <p className="legend">
              Met with Crime and Law & Order inspectors, submitted safety
              violation requests.
            </p>
          </div>
          <div>
            <img src={BannerImage2} alt="Testing no" />
            <p className="legend">
              Our members attended Corporation Association Meeting in GCC,
              Rippon Building
            </p>
          </div>
          <div>
            <img src={BannerImage3} alt="Testing no" />
            <p className="legend">
              Our Association Members meeting happened on 5th December, 2021
            </p>
          </div>
          <div>
            <img src={BannerImage4} alt="Testing no" />
            <p className="legend">
              Gave petition to Chennai Metro Water for Water Drainage System
            </p>
          </div>
        </Carousel>
      </div>
      <div className="welcome-section">
        <div className="">
          <img src={WelcomeBanner} />
        </div>
        <div className="">
          <h2>
            Welcome to Nookampalayam Link Road Residents Welfare Association
          </h2>
          <p>
            We are actively uniting, coordinating and involving all members from
            our area for an ultimate and a genuine cause:
            <strong> Development of our area</strong>
          </p>

          <p>
            In connection with this, we had conducted many meetings and it was
            decided to form an association as an outcome. Office bearers were
            elected unanimously.
          </p>

          <p>
            The first and the foremost task for us were to move for - requesting
            basic amenities i.e. metro water and drainage facilities, roads and
            street lights. We made petitions to respective departments
            requesting the same.
          </p>

          <p>
            We were successful in getting street lights for Sri Ganapathy Nagar
            2nd main road & it’s four cross streets. It was inaugurated by our
            area MLA Aravind Ramesh sir.
          </p>
          <Link to="/about">Learn more</Link>
        </div>
      </div>
      <div className="activities-section">
        <h2>Major Problems we are fighting for</h2>
        <ul>
          {activities.map((activity) => {
            return (
              <li>
                <div>
                  <img src={activity.icon} alt={activity.title} />
                  <h3>{activity.title}</h3>
                  <p>{activity.description}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="actions-section">
        <h2>Association in Action</h2>
        <ul>
          <li>
            <div>
              <img src={Collage1} alt="association-in-action" />
              <p>Street Name Board Installation</p>
            </div>
          </li>
          <li>
            <div>
              <img src={Collage2} alt="association-in-action" />
              <p>Our MLA cutting Ribbon</p>
            </div>
          </li>
          <li>
            <div>
              <img src={Collage3} alt="association-in-action" />
              <p>Rings Installation</p>
            </div>
          </li>
          <li>
            <div>
              <img src={Collage4} alt="association-in-action" />
              <p>Flood time actions</p>
            </div>
          </li>
          <li>
            <div>
              <img src={Collage5} alt="association-in-action" />
              <p>Raising road levels</p>
            </div>
          </li>
          <li>
            <div>
              <img src={Collage6} alt="association-in-action" />
              <p>IAS Officer and MLA visited our area</p>
            </div>
          </li>
        </ul>
        <Link to="/actions">View our latest actions</Link>
      </div>
    </Layout>
  );
}
